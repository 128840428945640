<template>
  <div :class="$style.component">
    <div :class="$style.image" :style="style">
      <base-image v-if="item.image" :image="item.image" />
    </div>
    <base-bodytext v-if="item.body" :text="item.body" :class="classes.body" />
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'
import BaseImage from '@/components/base-image'
import { mapState } from 'vuex'

export default {
  components: {
    BaseBodytext,
    BaseImage
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
    classes() {
      return {
        body: [
          this.$style.body,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    },
    style() {
      return {
        'padding-bottom': this.paddingBottom
      }
    },
    paddingBottom() {
      if (!this.item.image) return
      return (100 / this.item.image.ratio).toFixed(2) + '%'
    }
  }
}
</script>

<style lang="scss" module>
// .component {}

.image {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.body {
  @extend %fs-small;

  min-height: calc(var(--blank-line) * 3.1);
  padding: calc(var(--blank-line) / 2) 0;
  border-bottom: solid 1px var(--black);
  transition: border-color var(--mlong) ease;

  &.otherLang {
    border-color: var(--white);
  }
}
</style>
